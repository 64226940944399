import React from 'react'
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import imgDmark from '../../../assets/img/img_dmark.png';
import imgGoodDesign from '../../../assets/img/img_good_design.png';
import imgReddot from '../../../assets/img/img_reddot.png';

const AwardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
    margin-bottom: 3vh;
    // border: 1px solid green;
`;

const ImgAward = styled.img`
    width: 20%;
    margin-left: 3vw;
    margin-right: 3vw;
`

const ImgAwardDesktop = styled.img`
    width: 20%;
    // margin-left: 1vw;
    margin-right: 1vw;
`

const Awards = (props) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    if(props.isDesktop){
        return (
            <AwardsContainer>
                {/* {isDesktop ? 'desktop' : 'mobile'} */}
                <ImgAwardDesktop
                    src={imgDmark}
                />
                <ImgAwardDesktop
                    src={imgGoodDesign}
                />
                <ImgAwardDesktop
                    src={imgReddot}
                />
            </AwardsContainer>
        )
    } else {
        return (
            <AwardsContainer>
                {/* {isDesktop ? 'desktop' : 'mobile'} */}
                <ImgAward
                    src={imgDmark}
                />
                <ImgAward
                    src={imgGoodDesign}
                />
                <ImgAward
                    src={imgReddot}
                />
            </AwardsContainer>
        )
    }
    
}

export default Awards;
