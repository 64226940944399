import React from 'react'
import styled from 'styled-components';

const StyledContainer = styled.div`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: -5vh;

    `;
    const StyledImg = styled.img`
        width: 100vw;
        height: auto;
        object-fit: cover;
    `;


const ImgStoryRight = (props) => {

    

    const inlineStyle = {
        width: props.customWidth
    }

    return (
        <StyledContainer>
            <StyledImg style={inlineStyle} src={props.imgStory} />
        </StyledContainer>
        

    )
}

export default ImgStoryRight;
