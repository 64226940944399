import React from 'react'
import styled from 'styled-components';
import imgStory02a from '../../../assets/img/img_story_02a.jpg';
import imgStory02b from '../../../assets/img/img_story_02b.jpg';
import imgStory02c from '../../../assets/img/img_story_02c.jpg';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 3vw;    
    margin-bottom: 3vw;    
`;

const StyledTallImg = styled.img`
    width: 31vw;
`;

const StyledTallImgDesktop = styled.img`
    width: 20vw;
    margin-left: 1.5vw;
`;

const dummyDiv = styled.div`
    width: 5vw;
`

const ThreeImages = (props) => {

    if(props.isDesktop) {
        return (
            <Container>
                <StyledTallImgDesktop src={imgStory02a} />
                <StyledTallImgDesktop src={imgStory02b} />
                <StyledTallImgDesktop src={imgStory02c} />
            </Container>
    
        )
    } else {
        return (
            <Container>
                <StyledTallImg src={imgStory02a} />
                <StyledTallImg src={imgStory02b} />
                <StyledTallImg src={imgStory02c} />
            </Container>
    
        )
    }

    
}

export default ThreeImages;
