import React from 'react'
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import ImgStoryFullWidth from './../../modules/views/ImgStoryFullWidth';
import img_living_lifestyle_txt_shadowed from '../../../assets/img/img_living_lifestyle_txt_shadowed.svg';
import img_architect_txt_shadowed from '../../../assets/img/img_architect_txt_shadowed.svg';
import imgCarousel04 from '../../../assets/img/carousel_04.jpg';
import { ANIMATION_DURATION } from '../../../config';


const StyledContainer = styled.div` 
    position: relative;
`

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // border: 10px solid red;
`

const StyledHoverLogoLeft = styled.img`
    // border: 1px solid #000;
    text-align:center;
    width: 30vw;
`

const StyledHoverLogoRight = styled.img`
    // border: 1px solid #000;
    text-align:center;
    width: 30vw;
`



export const DesktopLivingLifestyleJumbotron = () => {
    return (
        <StyledContainer>


            <ImgStoryFullWidth
                imgStory={imgCarousel04} />

            <LogoContainer>
                <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
                    <a style={{ textDecoration: 'none', color: 'black' }} href="#id_link_desktop_living_lifestyle">
                        <StyledHoverLogoLeft src={img_living_lifestyle_txt_shadowed}></StyledHoverLogoLeft>
                    </a>
                </ScrollAnimation>
                <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
                    <a style={{ textDecoration: 'none', color: 'black' }} href="#id_link_desktop_architect">
                        <StyledHoverLogoRight src={img_architect_txt_shadowed}></StyledHoverLogoRight>
                    </a>
                </ScrollAnimation>

            </LogoContainer>



        </StyledContainer>
    )
}
