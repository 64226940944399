import React from 'react'
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Story from './Story';
import imgPicolo4Seats from '../../../assets/img/imgPicolo4Seats.png';
import { bodyLiving } from '../../../assets/txt/RawText';
import { ANIMATION_DURATION } from '../../../config';


const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    // border 1px solid black;

`

const StyledImgContainer = styled.div`
    width: 70vw;
    text-align: center;
    // border 1px solid red;
    
`

const StyledImg = styled.img`
    width: 70%;
`;

const DesktopLivingLifestyle = () => {
    return (
        <HorizontalContainer>

            <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInLeft">
                <StyledImgContainer>
                    <StyledImg src={imgPicolo4Seats} />
                </StyledImgContainer>
            </ScrollAnimation>

            <VerticalContainer>
                <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
                    <Story
                        isDesktop={true}
                        header='Living lifestyle'
                        headerAlign='flex-start'
                        textAlign='left'
                        storyWidth='80%'
                        marginLeft={'0vw'}
                        body={bodyLiving}
                    />
                </ScrollAnimation>
            </VerticalContainer>

        </HorizontalContainer>
    )
}

export default DesktopLivingLifestyle;
