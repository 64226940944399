import React from "react";
import Slider from "react-slick";
import styled from 'styled-components';
import imgCarousel01 from '../../../assets/img/carousel_01.jpg';
import imgCarousel02 from '../../../assets/img/carousel_02.jpg';
import imgCarousel03 from '../../../assets/img/carousel_03.jpg';
import imgCarousel04 from '../../../assets/img/carousel_04.jpg';
import imgCarousel05 from '../../../assets/img/carousel_05.jpg';
import imgArrowLeft from '../../../assets/img/img_arrow_left.png';
import imgArrowRight from '../../../assets/img/img_arrow_right.png';


const ImgSlide = styled.img`
  width: 100vw;
  height:  ${props => props.isDesktop ? "75vh" : "50vh"};
  object-fit: cover;
  
`;

const DesktopSampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <img className={className} src={imgArrowRight} onClick={onClick}

      style={{
        ...style,
        display: "block",
        width: '3vw',
        height: 'auto',
        marginRight: '5vw',
        zIndex: 11
      }}>

    </img>
  );
}

const DesktopSamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <img
      src={imgArrowLeft}
      className={className}
      style={{
        ...style,
        display: "block",
        width: '3vw',
        height: 'auto',
        marginLeft: '5vw',
        position: 'absolute',
        zIndex: 5
      }}
      onClick={onClick}
    />
  );
}


const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <img className={className} src={imgArrowRight} onClick={onClick}

      style={{
        ...style,
        display: "block",
        width: '3vw',
        height: 'auto',
        marginRight: '10vw',
        zIndex: 11
      }}>

    </img>
  );
}

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <img
      src={imgArrowLeft}
      className={className}
      style={{
        ...style,
        display: "block",
        width: '3vw',
        height: 'auto',
        marginLeft: '10vw',
        position: 'absolute',
        zIndex: 5
      }}
      onClick={onClick}
    />
  );
}




class Carousel extends React.Component {

  render() {
    let settings;

    if(this.props.isDesktop) {
      settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        nextArrow: <DesktopSampleNextArrow />,
        prevArrow: <DesktopSamplePrevArrow />,
      };
    } else {
      settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      };
    }
    return (
      <Slider {...settings}>
        <div>
          <ImgSlide isDesktop={this.props.isDesktop} src={imgCarousel01}></ImgSlide>
        </div>
        <div>
          <ImgSlide isDesktop={this.props.isDesktop} src={imgCarousel02}></ImgSlide>
        </div>
        <div>
          <ImgSlide isDesktop={this.props.isDesktop} src={imgCarousel03}></ImgSlide>
        </div>
        <div>
          <ImgSlide isDesktop={this.props.isDesktop} src={imgCarousel04}></ImgSlide>
        </div>
        <div>
          <ImgSlide isDesktop={this.props.isDesktop} src={imgCarousel05}></ImgSlide>
        </div>
      </Slider>
    );
  }
}



export default Carousel;
