import React from 'react'
import styled from 'styled-components';




const ImgStoryFullWidth = (props) => {

    const StyledImg = styled.img`
        width: 100vw;
        height: auto;
        object-fit: cover;
    `;

    return (
            <StyledImg src={props.imgStory} />
    )
}

export default ImgStoryFullWidth;
