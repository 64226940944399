import React from 'react'
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import ImgStoryFullWidth from './../../modules/views/ImgStoryFullWidth';
import imgProjectRefHeader from '../../../assets/img/imgProjectRefHeader.jpg';
import img_projectref_txt_shadowed from '../../../assets/img/img_projectref_txt_shadowed.svg';
import { ANIMATION_DURATION } from '../../../config';

const StyledContainer = styled.div` 
    position: relative;
`


const StyledHoverLogo = styled.img`
    // border: 1px solid #000;
    text-align:center;
    width: 40vw;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`

export const ProjectRefJumbotron = () => {
    return (
        <StyledContainer>
            <ImgStoryFullWidth
                imgStory={imgProjectRefHeader} />

            {/* <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn"> */}
                <StyledHoverLogo src={img_projectref_txt_shadowed}></StyledHoverLogo>
            {/* </ScrollAnimation> */}
        </StyledContainer>
    )
}
