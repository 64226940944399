import React from 'react'
import styled from 'styled-components';




const Img4Seats = (props) => {

    const StyledImg = styled.img`
        width: 80vw;
        height: auto;
        object-fit: cover;
    `;

    return (
        <div align='center'>
            <StyledImg src={props.imgStory} />
        </div>
    )
}

export default Img4Seats;
