import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styled from 'styled-components';
import Carousel from '../components/Carousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img_galvanii_txt_shadowed from '../../../assets/img/img_galvanii_txt_shadowed.png';


const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        position: 'relative',
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: '5vh'
    },
    container: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        height: '100%',
        height: '80vh',
        borderRadius: '25px',

        [theme.breakpoints.down('xs')]: {
            height: '20vh',
        },

        color: theme.palette.text.secondary,
    },
    svg: {
        width: 100,
        height: 100,
    },
    polygon: {
        fill: theme.palette.common.white,
        stroke: theme.palette.divider,
        strokeWidth: 1,
    },
}));

const StyledHoverLogoDesktop = styled.img`
    // border: 1px solid #000;
    text-align:center;
    width: 20vw;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`

const StyledHoverLogo = styled.img`
    // border: 1px solid #000;
    text-align:center;
    width: 25vw;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`



const TopMenu = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Carousel isDesktop={props.isDesktop}>
            </Carousel>

            {props.isDesktop ?
                <StyledHoverLogoDesktop src={img_galvanii_txt_shadowed}></StyledHoverLogoDesktop>
                :
                <StyledHoverLogo src={img_galvanii_txt_shadowed}></StyledHoverLogo>
            }

        </div>
    );


}

export default TopMenu;



// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   },
// }));

// export default function FullWidthGrid() {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <Grid container spacing={3}>
//         <Grid item xs={12} sm={6} md={3}>
//           <Paper className={classes.paper}>xs=12 sm=6</Paper>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Paper className={classes.paper}>xs=12 sm=6</Paper>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Paper className={classes.paper}>xs=12 sm=6</Paper>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Paper className={classes.paper}>xs=12 sm=6</Paper>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }
