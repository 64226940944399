import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import "animate.css/animate.min.css";
// Drawer imports
import clsx from 'clsx';
// --- Post bootstrap -----
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import imgPicolo4Seats from '../assets/img/imgPicolo4Seats.png';
import imgProjectRefGrid from '../assets/img/imgProjectRefGrid.jpg';
import imgProjectRefGridDesktop from '../assets/img/imgProjectRefGridDesktop.jpg';
import imgStairs from '../assets/img/imgStairs.png';
// images
import imgStory01 from '../assets/img/img_story_01.jpg';
import {
  bodyArchitect, bodyAwards,
  bodyGalvanized,
  bodyLiving,

  bodyProjectRef
} from '../assets/txt/RawText';
import { ANIMATION_DURATION } from '../config';
import AppAppBar from './modules/views/AppAppBar';
import Awards from './modules/views/Awards';
import Contact from './modules/views/Contact';
import DesktopArchitect from './modules/views/DesktopArchitect';
import DesktopAwards from './modules/views/DesktopAwards';
import DesktopContact from './modules/views/DesktopContact';
import DesktopLivingLifestyle from './modules/views/DesktopLivingLifestyle';
import { DesktopLivingLifestyleJumbotron } from './modules/views/DesktopLivingLifestyleJumbotron';
import DesktopProductGridV3 from './modules/views/DesktopProductGridV3';
import { DesktopProjectRefJumbotron } from './modules/views/DesktopProjectRefJumbotron';
import DesktopThreeImages from './modules/views/DesktopThreeImages';
import DesktopTopMenu from './modules/views/DesktopTopMenu';
import Img4Seats from './modules/views/Img4Seats';
import ImgStoryFullWidth from './modules/views/ImgStoryFullWidth';
import ImgStoryRight from './modules/views/ImgStoryRight';
import { LivingLifestyleJumbotron } from './modules/views/LivingLifestyleJumbotron';
import OnlineCatalog from './modules/views/OnlineCatalog';
import OnlineCatalogDesktop from './modules/views/OnlineCatalogDesktop';
import ProductGridV3 from './modules/views/ProductGridV3';
import { ProjectRefJumbotron } from './modules/views/ProjectRefJumbotron';
import Story from './modules/views/Story';
import ThreeImages from './modules/views/ThreeImages';
import TopMenu from './modules/views/TopMenu';
import withRoot from './modules/withRoot';






const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});






// https://stackoverflow.com/questions/43441856/how-to-scroll-to-an-element



function Index() {

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // Scroll to ref code
  // const scrollToRef = (ref) => {
  //   console.log('scrollToRef()');
  //   console.log(ref);
  //   window.scrollTo(0, ref.current.offsetTop)

  // };
  // const refProjectReference = useRef(null)

  // const scrollToProjectRef = () => {
  //   console.log('scrollToProjectRef()');
  //   console.log(refProjectReference);
  //   scrollToRef(refProjectReference);
  // }

  // Drawer code
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {

    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // end Drawer code


  if (isDesktop) {
    return (
      <React.Fragment>
        <div style={{ textDecoration: 'none', color: 'black', top: '-250px', visibility: 'hidden' }} id='id_home'></div>
        <AppAppBar isDesktop />


        <DesktopTopMenu isDesktop />

        <DesktopAwards></DesktopAwards>

        <div id='id_material'></div>
        <DesktopThreeImages></DesktopThreeImages>

        <div id='id_product_service'></div>
        <DesktopLivingLifestyleJumbotron></DesktopLivingLifestyleJumbotron>

        <div id='id_link_desktop_living_lifestyle' style={{ display: 'block', position: 'relative', top: '-8vh', visibility: 'hidden' }}></div>
        <DesktopLivingLifestyle></DesktopLivingLifestyle>

        <div id='id_link_desktop_architect' style={{ display: 'block', position: 'relative', top: '-8vh', visibility: 'hidden' }}></div>
        <DesktopArchitect></DesktopArchitect>

        <DesktopProductGridV3></DesktopProductGridV3>

        <div id='id_project_ref'></div>
        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
        <DesktopProjectRefJumbotron></DesktopProjectRefJumbotron>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
          <Story
            isDesktop
            header='PROJECTS REFERENCE'
            headerAlign='center'
            textAlign='center'
            marginTop='7vh'
            marginBottom='7vh'
            body={bodyProjectRef} />
        </ScrollAnimation>

        <ImgStoryFullWidth
          imgStory={imgProjectRefGridDesktop}
        />

        <OnlineCatalogDesktop></OnlineCatalogDesktop>

        <div id='id_contact'></div>
        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
          <DesktopContact></DesktopContact>
        </ScrollAnimation>


      </React.Fragment>

    );
  } else {



    return (
      <React.Fragment>
        <div id='id_home' style={{ display: 'block', position: 'relative', top: '-8vh', visibility: 'hidden' }}></div>
        <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
          <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer('right', false)}
            onKeyDown={toggleDrawer('right', false)}
          >
            <List>

              <a style={{ textDecoration: 'none', color: 'black' }} href="#id_home">
                <ListItem button key='HOME'>

                  <ListItemText primary='HOME' />

                </ListItem>
              </a>

              <a style={{ textDecoration: 'none', color: 'black' }} href="#id_material">
                <ListItem button key='MATERIAL'>
                  <ListItemText primary='MATERIAL' />
                </ListItem>
              </a>

              <a style={{ textDecoration: 'none', color: 'black' }} href="#id_product_service">
                <ListItem button key='PRODUCT &amp; SERVICE'>
                  <ListItemText primary='PRODUCT &amp; SERVICE' />
                </ListItem>
              </a>

              <a style={{ textDecoration: 'none', color: 'black' }} href="#id_project_ref">
                <ListItem button key='PROJECT REFERENCE'>
                  <ListItemText primary='PROJECT REFERENCE' />
                </ListItem>
              </a>

              <a style={{ textDecoration: 'none', color: 'black' }} href="#id_contact">
                <ListItem button key='CONTACT'>
                  <ListItemText primary='CONTACT' />
                </ListItem>
              </a>
            </List>


          </div>
        </Drawer >

        <AppAppBar toggleDrawer={toggleDrawer} />
        <TopMenu />


        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
          <Story
            textAlign='center'
            body={bodyAwards} />
        </ScrollAnimation>

        <Awards />



        <ImgStoryFullWidth
          imgStory={imgStory01} />


        <ThreeImages />
        <div id='id_material' style={{ display: 'block', position: 'relative', top: '-8vh', visibility: 'hidden' }}></div>

        <br />
        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
          <Story
            header='GALVANIZED'
            headerAlign='flex-start'
            textAlign='left'
            body={bodyGalvanized} />
        </ScrollAnimation>

        <div id='id_product_service' style={{ display: 'block', position: 'relative', top: '-8vh', visibility: 'hidden' }}></div>

        <br />
        <br />
        <br />
        
        <LivingLifestyleJumbotron></LivingLifestyleJumbotron>


        
        <br />

        <div id='id_link_living_lifestyle' style={{ display: 'block', position: 'relative', top: '-8vh', visibility: 'hidden' }}></div>
        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
          <Story
            header='Living lifestyle'
            headerAlign='flex-start'
            textAlign='left'
            body={bodyLiving} />
        </ScrollAnimation>
        <br />
        

        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInLeft">
          <Img4Seats
            imgStory={imgPicolo4Seats} />
        </ScrollAnimation>

        <br />

        <div id='id_link_architect' style={{ display: 'block', position: 'relative', top: '-8vh', visibility: 'hidden' }}></div>
        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
          <Story
            header='Architect'
            headerAlign='flex-end'
            textAlign='right'
            storyWidth='55vw'
            body={bodyArchitect} />
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInRight">
          <ImgStoryRight
            imgStory={imgStairs}
            customWidth='60vw' />

        </ScrollAnimation>

        {/* <ProductGrid></ProductGrid> */}

        <ProductGridV3></ProductGridV3>

        <div id='id_project_ref' style={{ display: 'block', position: 'relative', top: '-8vh', visibility: 'hidden' }}></div>
        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
        <ProjectRefJumbotron></ProjectRefJumbotron>
        </ScrollAnimation>

        <br />
        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
          <Story
            header='PROJECTS REFERENCE'
            headerAlign='center'
            textAlign='center'
            body={bodyProjectRef} />
        </ScrollAnimation>

        <br />
        <br />
        <br />

        <ImgStoryFullWidth
          imgStory={imgProjectRefGrid}
        />

        <OnlineCatalog></OnlineCatalog>

        <div id='id_contact' style={{ display: 'block', position: 'relative', top: '-8vh', visibility: 'hidden' }}></div>
        <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
          <Contact></Contact>
        </ScrollAnimation>

      </React.Fragment >
    );
  }

}

export default withRoot(Index);
