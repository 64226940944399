import React from 'react'
import styled from 'styled-components';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import ImgStoryFullWidth from './../../modules/views/ImgStoryFullWidth';
import imgProjectRefHeader from '../../../assets/img/imgProjectRefHeader.jpg';
import img_living_lifestyle_txt_shadowed from '../../../assets/img/img_living_lifestyle_txt_shadowed.svg';
import img_architect_txt_shadowed from '../../../assets/img/img_architect_txt_shadowed.svg';
import imgCarousel04 from '../../../assets/img/carousel_04.jpg';
import { ANIMATION_DURATION } from '../../../config';


const StyledContainer = styled.div` 
    position: relative;
`

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // border: 10px solid red;
`

const StyledHoverLogoTop = styled.img`
    // border: 1px solid #000;
    text-align:center;
    width: 45vw;
    margin-bottom: -10%;
    // border: 1px solid red;
`

const StyledHoverLogoBottom = styled.img`
    // border: 1px solid #000;
    text-align:center;
    width: 45vw;
    margin-top: -10%;
`



export const LivingLifestyleJumbotron = () => {
    return (
        <StyledContainer>


            <ImgStoryFullWidth
                imgStory={imgCarousel04} />

            <LogoContainer>
                <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">

                    <a style={{ textDecoration: 'none', color: 'black' }} href="#id_link_living_lifestyle">
                        <StyledHoverLogoTop src={img_living_lifestyle_txt_shadowed}></StyledHoverLogoTop>
                    </a>
                </ScrollAnimation>
                <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">


                    <a style={{ textDecoration: 'none', color: 'black' }} href="#id_link_architect">
                        <StyledHoverLogoBottom src={img_architect_txt_shadowed}></StyledHoverLogoBottom>
                    </a>
                </ScrollAnimation>

            </LogoContainer>



        </StyledContainer>
    )
}
