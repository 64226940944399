import React from 'react'
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import Awards from './Awards';
import Story from './Story';
import imgStory01 from '../../../assets/img/img_story_01.jpg';
import { bodyAwards } from '../../../assets/txt/RawText';
import { ANIMATION_DURATION } from '../../../config';


const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    // border 1px solid black;

`

const StyledImg = styled.img`
    width: 60vw;
`;

const DesktopAwards = () => {
    return (
        <HorizontalContainer>
            <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInLeft">
                <StyledImg src={imgStory01} />
            </ScrollAnimation>


            <VerticalContainer>
                <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
                    <Story
                        isDesktop={true}
                        marginLeft={'6.5vw'}
                        textAlign='left'
                        body={bodyAwards} />
                    <Awards isDesktop={true} />
                </ScrollAnimation>
            </VerticalContainer>


        </HorizontalContainer>
    )
}

export default DesktopAwards;
