import React from 'react'
import styled from 'styled-components';
import imgSampleQr from '../../../assets/img/img_sample_qr.png';

const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    // height: 50vh;
    background-color: #e1e1e1;
    padding-top: 7vh;
    padding-bottom: 10vh;
`

const ContactTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33vw;

    border-right: 1px solid black;
`


const AddressTopicContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left 7vw;
    width: 30vw;
    // border 1px solid black;
`

const AddressTopicText = styled.span`
    font-weight: bold;
    font-size: 0.7em;
    margin-bottom: 1vh;
`

const AddressDetailText = styled.span`
    font-size: 0.7em;
    margin-bottom: 1vh;
`

const AddressDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 36vw;
    word-wrap: break-word;
    // border 1px solid black;
    
`


const VerticalLine = styled.div`
    border-left: 1px solid black;
    height: 10vh;
`

const StyledImg = styled.img`
    width: 50%;
    // margin-right: 15%;
`;

const StyledHeader = styled.span`
    font-weight: bold;
    font-size: 1em;
    text-align: center;
    margin-bottom: 1vh;
    
`

const StyledContactButton = styled.button`
    width: 25vw;
    background-color: #808080;
    color: white;
    font-weight: bold;
    font-size: 1em;
    border: none;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    margin-top: 2vh;


`



const Contact = () => {
    return (
        <HorizontalContainer>
            <ContactTextContainer>
                <StyledHeader>CONTACT</StyledHeader>
                <StyledImg src={imgSampleQr}></StyledImg>
            </ContactTextContainer>



            <AddressTopicContainer>
                <AddressTopicText>WEBSITE</AddressTopicText>
                <AddressTopicText>EMAIL</AddressTopicText>
                <AddressTopicText>TEL</AddressTopicText>
                <AddressTopicText>FAX</AddressTopicText>
                <AddressTopicText>ADDRESS</AddressTopicText>

            </AddressTopicContainer>

            <AddressDetailsContainer>
                <AddressDetailText>GALVANII.CO.TH</AddressDetailText>
                <AddressDetailText>CONTACT@GALVANII.CO.TH</AddressDetailText>
                <AddressDetailText>(+66) 38 845 846</AddressDetailText>
                <AddressDetailText>(+66) 38 846 064-7</AddressDetailText>
                <AddressDetailText>9 MOO. 1</AddressDetailText>
                <AddressDetailText>SUWINTAWONG RD.,KM.60-61</AddressDetailText>
                <AddressDetailText>KLONG-UDOMCHOLJORN,</AddressDetailText>
                <AddressDetailText>MUANG, CHACHOENGSAO,</AddressDetailText>
                <AddressDetailText>THAILAND, 24000</AddressDetailText>
                <a href="mailto:marketing@uniongalv.com" target='_blank'><StyledContactButton>CONTACT</StyledContactButton></a>
                
            </AddressDetailsContainer>
        </HorizontalContainer>
    )
}

export default Contact;
