const bodyAwards = "galvanii is the manufacturer of furniture with galvanized steel as main material. At galvanii,we believe in smart design that lasts over decades.We incorporate aesthetic design with quality materials. Through our expertise in hot-dip galvanizing, along with cutting-edge design, galvanii’s collection offer furniture that comes under unique concept to suit your needs and lifestyle.";
const bodyGalvanized = "Zinc is a natural substance, essential to human and all oraganic livings. For galvanii, zinc is utilized in galvanization process, resulting in galvanized steel that offer decades of lasting furniture. Through the use of long-lasting and 100% recyclable material, we help create the path toward sustainable development.";
const bodyLiving = "The design concept of galvanii originally derives from the expertise of the manufacturer who is specialist in hot-dip galvanizing. Usually, the end products are heavy construction materials, cable, or bridge for example. Combining that with the desire to create artistic outdoor furniture that not only has smart function but also highly durable and add aesthetic to the outdoor space.";
const bodyArchitect = "The most Universal Design is the one that strike the right balance of simplicity. edge collection is just the most simple design and material fall into the right place.";
const bodyProjectRef = "Smart design and hardwearing galvanized steel are galvanii’s identity. Currently, galvanized steel is widely used in heavy industry like bridge and highway construction, cable tray, etc, given its durability in diverse environment. galvanii adds design concept that was meant to bring the tough material into our everyday life by adding attractive shape to the unique texture of galvanized steel resulting in outdoor furniture that not only has smart function but also highly durable and add aesthetic to the space.";

const gridItem01 = "ACCESSORY LANTERN";
const gridItem02 = "AUNGLO BAR STOOL";
const gridItem03 = "CHAMFER BAR TABLE -White marble";
const gridItem04 = "CONTOUR STOOL -Black";
const gridItem05 = "CROSS SUNBED";
const gridItem06 = "EMBER SHELF";
const gridItem07 = "GABION CENTRE TABLE";
const gridItem08 = "GRID ARMCHAIR Nopad";
const gridItem09 = "GRID BAR STOOL";
const gridItem10 = "KAMEN -Black";
const gridItem11 = "MOF CENTRE TABLE";
const gridItem12 = "MOF SOFA";
const gridItem13 = "PAVENII ARMCHAIR -Black";
const gridItem14 = "PAVENII TABLE -Black";
const gridItem15 = "PICOLO 2SEAT -Black";
const gridItem16 = "SHADE";

export { 
    bodyAwards,
    bodyGalvanized,
    bodyLiving,
    bodyArchitect,
    bodyProjectRef,
    gridItem01,
    gridItem02,
    gridItem03,
    gridItem04,
    gridItem05,
    gridItem06,
    gridItem07,
    gridItem08,
    gridItem09,
    gridItem10,
    gridItem11,
    gridItem12,
    gridItem13,
    gridItem14,
    gridItem15,
    gridItem16
};
