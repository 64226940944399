import React from 'react'
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import imgCatalogLeft from '../../../assets/img/img_catalog_left.jpg';
import imgCatalogRight from '../../../assets/img/img_catalog_right.jpg';
import { ANIMATION_DURATION } from '../../../config';




const OnlineCatalog = (props) => {
    // console.log(props);

    const StyledContainer = styled.div`
        display: flex;
        flex-direction: column;
        text-align: ${props.textAlign};
        font-family: HelveticaNeueMedium;
        justify-content: center;
        align-items: center;
        margin-bottom: 3vh;
        margin-left: 5vw;
        margin-right: 5vw;
        width: ${props.storyWidth};
    `;

    const StyledHorizontalContainer = styled.div`
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 3vh;
        margin-bottom: 3vh;
        // border: 1px solid blue;
    `;


    const StyledColumn = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `;

    const StyledHeader = styled.p`
        font-weight: bold;
        font-size: 1.2em;
        margin-bottom: 3vh;
        color: black;
    `;

    const StyledSubHeader = styled.p`
        font-size: 1em;
        color: black;
    `;

    const StyledFooter = styled.p`
        font-size: 1em;
        color: black;
    `;

    const StyledImg = styled.img`
        width: 45vw;
        object-fit: cover;
        margin-top: 3vh;
        // border: 1px solid red;
    `;

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <StyledContainer>
            <StyledHeader>ONLINE CATALOG</StyledHeader>
            <StyledHorizontalContainer>
                <StyledColumn>
                    <StyledSubHeader>LIVING LIFESTYLE</StyledSubHeader>
                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
                        <a href="https://drive.google.com/file/d/1-q8udTZHvWOSsnWqdcaX5ubTE0gepDCL/view?usp=sharing" target='_blank'>
                            <StyledImg src={imgCatalogLeft} />
                        </a>
                    </ScrollAnimation>
                </StyledColumn>
                <StyledColumn>
                    <StyledSubHeader>ARCHITECTURE</StyledSubHeader>
                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
                        <a href="https://drive.google.com/file/d/1mrzkMulwaYiJMoncTtau4bng0Sog8CfA/view?usp=sharing" target='_blank'>
                            <StyledImg src={imgCatalogRight} />
                        </a>
                    </ScrollAnimation>
                </StyledColumn>
            </StyledHorizontalContainer>
            <StyledFooter>CLICK TO OPEN AND DOWNLOAD</StyledFooter>
        </StyledContainer>
    )
}

export default OnlineCatalog;
