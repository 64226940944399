import React from 'react'
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';



const Story = (props) => {
    // console.log(props);

    const StyledContainer = styled.div`
        display: flex;
        flex-direction: column;
        text-align: ${props.textAlign};
        font-family: HelveticaNeueMedium;
        justify-content: center;
        align-items: center;
        margin-bottom: 3vh;
        margin-left: ${props => props.marginLeft ? props.marginLeft : '5vw'};
        margin-right: ${props => props.marginRight ? props.marginRight : '5vw'};
        margin-top: ${props => props.marginTop ? props.marginTop : '0vh'};
        margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0vh'};
        width: ${props.storyWidth};
        font-size: ${props => props.isDesktop ? '1em' : '0.9em'};
    `;

    const StyledHeader = styled.p`
        font-weight: bold;
        font-size: ${props => props.isDesktop ? '2.5em' : '1.5em'};
        align-self: ${props.headerAlign};
    `

    const theme = useTheme();
    return (
        <StyledContainer 
            isDesktop={props.isDesktop}
            marginLeft={props.marginLeft} 
            marginRight={props.marginRight}
            marginTop={props.marginTop}
            marginBottom={props.marginBottom}
            
            textAlign={props.textAlign}>

                {props.header && <StyledHeader isDesktop={props.isDesktop} headerAlign={props.headerAlign}>{props.header}</StyledHeader>}
            
                {/* {`${isDesktop}`}  */}
                {props.body}
                {/* galvanii is the manufacturer of furniture with galvanized steel as main material. At galvanii, we believe in smart design that lasts over decades. We incorporate aesthetic design with quality materials. Through our expertise in hot-dip galvanizing, along with cutting-edge design, galvanii’s collection offer furniture that comes under unique concept to suit your needs and lifestyle. */}
        </StyledContainer>
    )
}

export default Story;
