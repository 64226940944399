import React from 'react'
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import Awards from './Awards';
import Story from './Story';
import imgStairs from '../../../assets/img/imgStairs.png';
import { bodyArchitect } from '../../../assets/txt/RawText';
import { ANIMATION_DURATION } from '../../../config';

const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    // border 1px solid black;

`

const StyledImg = styled.img`
    width: 47vw;
    margin-left: 0vw;
`;



const DesktopArchitect = () => {
    return (
        <HorizontalContainer>
            <VerticalContainer>
            <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeIn">
                <Story
                    isDesktop={true}
                    header='Architect'
                    headerAlign='flex-end'
                    textAlign='right'
                    storyWidth='25vw'
                    marginRight='5vw'
                    marginBottom='20vh'
                    body={bodyArchitect}
                     />
                     </ScrollAnimation>
            </VerticalContainer>

            <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInRight">
            <StyledImg src={imgStairs} />
            </ScrollAnimation>

        </HorizontalContainer>
    )
}

export default DesktopArchitect;
