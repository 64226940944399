import imgLogoGalvanii from '../../../assets/img/logo_galvanii_black.svg';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';

const ImgLogo = styled.img`
  height: 9vh;
`;

const ImgLogoDesktop = styled.img`
  height: 12vh;
`;


const ToolbarButton = styled(Button)`
  margin-right: 3vw;
  font-size: 1.3em;
`



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  rootMobile: {
    flexGrow: 1,
    marginBottom: '10vh'
  },
  appBar: {
    color: 'black',
    backgroundColor: 'white',
  },
  toolBar: {
    // boxShadow: 0
  },
  menuButton: {
    // marginRight: theme.spacing(0),
  },
  empty: {
    flexGrow: 1,
  },
}));


const AppAppbar = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  if (props.isDesktop) {
    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static" elevation={0}>
          <Toolbar className={classes.toolBar}>

            {/* <Typography variant="h6" className={classes.title}>
              News
            </Typography> */}

            <ImgLogoDesktop src={imgLogoGalvanii}></ImgLogoDesktop>
            <div className={classes.empty}>

            </div>
            <a style={{ textDecoration: 'none', color: 'black' }} href="#id_home">
            <ToolbarButton color="inherit" style={{ fontWeight: 'lighter' }}>HOME</ToolbarButton>
            </a>

            <a style={{ textDecoration: 'none', color: 'black' }} href="#id_material">
            <ToolbarButton color="inherit" style={{ fontWeight: 'lighter' }}>MATERIAL</ToolbarButton>
            </a>

            <a style={{ textDecoration: 'none', color: 'black' }} href="#id_product_service">
            <ToolbarButton color="inherit" style={{ fontWeight: 'lighter' }}>PRODUCT &amp; SERVICE</ToolbarButton>
            </a>

            <a style={{ textDecoration: 'none', color: 'black' }} href="#id_project_ref">
            <ToolbarButton color="inherit" style={{ fontWeight: 'lighter' }}>PROJECT REFERENCE</ToolbarButton>
            </a>

            <a style={{ textDecoration: 'none', color: 'black' }} href="#id_contact">
            <ToolbarButton color="inherit" style={{ fontWeight: 'lighter' }}>CONTACT</ToolbarButton>
            </a>

            {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton> */}
          </Toolbar>
        </AppBar>
      </div>
    );
  } else {


    return (
      <div className={classes.rootMobile}>
        <AppBar className={classes.appBar} position="fixed" elevation={0}>
          <Toolbar className={classes.toolBar}>

            {/* <Typography variant="h6" className={classes.title}>
              News
            </Typography> */}

            <ImgLogo src={imgLogoGalvanii}></ImgLogo>
            <div className={classes.empty}>

            </div>
            {/* <Button color="inherit">{`desktop = ${isDesktop}` }</Button>
            <Button color="inherit">HOME</Button>
            <Button color="inherit">MATERIAL</Button>
            <Button color="inherit">PRODUCT &amp; SERVICE</Button>
            <Button color="inherit">CONTACT</Button> */}
            {/* <Button >right</Button> */}
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={props.toggleDrawer('right', true)}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    );
  }

}

export default AppAppbar;
