import React, { Component } from "react";
import styled from 'styled-components';
import StackGrid from "react-stack-grid";
import imgGridItems01 from '../../../assets/img/img_grid_item_01.png';
import imgGridItems02 from '../../../assets/img/img_grid_item_02.png';
import imgGridItems03 from '../../../assets/img/img_grid_item_03.png';
import imgGridItems04 from '../../../assets/img/img_grid_item_04.png';
import imgGridItems05 from '../../../assets/img/img_grid_item_05.png';
import imgGridItems06 from '../../../assets/img/img_grid_item_06.png';
import imgGridItems07 from '../../../assets/img/img_grid_item_07.png';
import imgGridItems08 from '../../../assets/img/img_grid_item_08.png';
import imgGridItems09 from '../../../assets/img/img_grid_item_09.png';
import imgGridItems10 from '../../../assets/img/img_grid_item_10.png';
import imgGridItems11 from '../../../assets/img/img_grid_item_11.png';
import imgGridItems12 from '../../../assets/img/img_grid_item_12.png';
import imgGridItems13 from '../../../assets/img/img_grid_item_13.png';
import imgGridItems14 from '../../../assets/img/img_grid_item_14.png';
import imgGridItems15 from '../../../assets/img/img_grid_item_15.png';
import imgGridItems16 from '../../../assets/img/img_grid_item_16.png';
import ScrollAnimation from "react-animate-on-scroll";
import { ANIMATION_DURATION } from '../../../config';

import {
    gridItem01,
    gridItem02,
    gridItem03,
    gridItem04,
    gridItem05,
    gridItem06,
    gridItem07,
    gridItem08,
    gridItem09,
    gridItem10,
    gridItem11,
    gridItem12,
    gridItem13,
    gridItem14,
    gridItem15,
    gridItem16
} from '../../../assets/txt/RawText';

const StyledContainerLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #dddddd;
    word-wrap: break-word;
    text-align: left;
    padding-top: 6vw;
    padding-bottom: 3vw;
    padding-left: 3vw;
    padding-right: 3vw;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 2vh;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #dddddd;
    word-wrap: break-word;
    text-align: left;
    padding-top: 6vw;
    padding-bottom: 3vw;
    padding-left: 3vw;
    padding-right: 3vw;
    margin-right: 5vw;
    margin-bottom: 2vh;
`;



const StyledImg01 = styled.img`
  width: 60%;
  object-fit: cover;
`;

const StyledGridText = styled.span`
    font-size: 0.5em;
    font-weight: bold;
    color: black;
    margin-top: 3vw;
    align-self: flex-start;
`;

const GridContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 7vh;
    margin-bottom: 3vh;
    // border 1px solid black;
`

const GridColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // border 1px solid blue;
`

class ProductGridV3 extends Component {
    render() {

        return (
            <GridContainer>
                <GridColumn>
                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainerLeft key="img03">
                            <StyledImg01 src={imgGridItems03} />
                            <StyledGridText>{gridItem03}</StyledGridText>
                        </StyledContainerLeft>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainerLeft key="img02">
                            <StyledImg01 src={imgGridItems02} />
                            <StyledGridText>{gridItem02}</StyledGridText>
                        </StyledContainerLeft>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainerLeft key="img04">
                            <StyledImg01 src={imgGridItems04} />
                            <StyledGridText>{gridItem04}</StyledGridText>
                        </StyledContainerLeft>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainerLeft key="img10">
                            <StyledImg01 src={imgGridItems10} />
                            <StyledGridText>{gridItem10}</StyledGridText>
                        </StyledContainerLeft>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainerLeft key="img15">
                            <StyledImg01 src={imgGridItems15} />
                            <StyledGridText>{gridItem15}</StyledGridText>
                        </StyledContainerLeft>
                    </ScrollAnimation>

                </GridColumn>



                <GridColumn>
                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img05">
                            <StyledImg01 src={imgGridItems05} />
                            <StyledGridText>{gridItem05}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img06">
                            <StyledImg01 src={imgGridItems06} />
                            <StyledGridText>{gridItem06}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img11">
                            <StyledImg01 src={imgGridItems11} />
                            <StyledGridText>{gridItem11}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img12">
                            <StyledImg01 src={imgGridItems12} />
                            <StyledGridText>{gridItem12}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img016">
                            <StyledImg01 src={imgGridItems16} />
                            <StyledGridText>{gridItem16}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>


                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img09">
                            <StyledImg01 src={imgGridItems09} />
                            <StyledGridText>{gridItem09}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>
                </GridColumn>



                <GridColumn>
                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img07">
                            <StyledImg01 src={imgGridItems07} />
                            <StyledGridText>{gridItem07}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img14">
                            <StyledImg01 src={imgGridItems14} />
                            <StyledGridText>{gridItem14}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img13">
                            <StyledImg01 src={imgGridItems13} />
                            <StyledGridText>{gridItem13}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>

                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img08">
                            <StyledImg01 src={imgGridItems08} />
                            <StyledGridText>{gridItem08}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>



                    <ScrollAnimation animateOnce={true} duration={ANIMATION_DURATION} animateIn="animate__animated animate__fadeInUp">
                        <StyledContainer key="img01">
                            <StyledImg01 src={imgGridItems01} />
                            <StyledGridText>{gridItem01}</StyledGridText>
                        </StyledContainer>
                    </ScrollAnimation>
                </GridColumn>
            </GridContainer>

        );




    }
}

export default ProductGridV3;
